


.home-container section {
    margin: 1.5rem 0;
    padding: 3rem 0;
}
.home-container section:hover {
        box-shadow:var(--tshade);
  transform: scale(1.1, 1.1);
}

.nonprime-container {
            background-image: linear-gradient(
        to top,
         #611821 0%, 
         #771c26 25%, 
         #8e202b 50%,
        #a5242f 75%,
        #bd2932 100%
         );
    border-radius: var(--brad);
    background-size: 150% 150%;
    animation: movingGradient 10s linear infinite alternate;
    color: var(--sec-t);
}
.s-nonprime-container {
            background-image: linear-gradient(
        to top,
         #611821 0%, 
         #771c26 25%, 
         #8e202b 50%,
        #a5242f 75%,
        #bd2932 100%
         );
    border-radius: var(--s-brad);
    background-size: 150% 150%;
    animation: movingGradient 10s linear infinite alternate;
    color: var(--sec-t);
}
.user-prime-container {
            background-image: linear-gradient(
        to top,
        #fffd9b 0%, 
         #eee588 25%, 
         #ddcd76 50%,
        #cbb665 75%,
        #b9a054 100%
         );
    border-radius: var(--brad);
    background-size: 150% 150%;
    animation: movingGradient 10s linear infinite alternate;
    color: var(--pri-t);
}
.s-prime-container {
            background-image: linear-gradient(
        to top,
         #fffd9b 0%, 
         #eee588 25%, 
         #ddcd76 50%,
        #cbb665 75%,
        #b9a054 100%
         );
    border-radius: var(--s-brad);
    background-size: 150% 150%;
    animation: movingGradient 10s linear infinite alternate;
    color: var(--pri-t);
}

.norm-container {
    background-color: var(--form-c);
    border-radius: var(--brad);
    color: var(--pri-t);
}



/* Search */
#search {
display: flex;
flex-direction: row;
margin: 1rem 0 1.5rem 0;
padding: 2rem 0;
height: 1rem;
}
.input-icon{
    padding: 0 2rem ;
}
.search-input{
    height: 1.5rem;
    width: 100%;
    border: none;
    font-size: 1.25rem;
    /* border: 1px solid black; */
}
.backspace-icon{

}

/* Prime News */
#prime{
    margin: 1.5rem 0 3rem 0;
}

.news-container {
    display: grid;
    grid-template-columns: 6% 1fr 0.2fr 6%;;
    grid-template-rows: repeat(5, auto);
    width: 100%;
    height: auto;
}


@keyframes movingGradient {
    from {background-position: 0 0;}
    to{ background-position: 100% 100%;}
}


/* Will be universal */

iframe {
border: none;
}

/* background-image: linear-gradient(to right top, ,  ); */
.pr-title{
    grid-column:2/4 ;
    grid-row:1/2;
  /* border: 1px solid white; */

}

.pr-user{
  padding-top: 20px;
  width: 100%;
  grid-column: 2/3;
  grid-row: 3/4;
  /* border: 1px solid white; */
}

.pr-user-post-info{
  display: grid;
   grid-template-columns: 25% 75%;
  grid-template-rows: 50% 50%;
}
.pr-info-pic{
  /* width: 40%; */
  grid-column: 1/2;
  grid-row: 1/3;
  /* margin: 20px; */

}
.pr-info-name{
  grid-column: 2/3;
  grid-row: 1/2;
    justify-self: start;
  align-self: end;
  padding-left: 20px;
}
.pr-info-time{
    grid-column: 2/3;
  grid-row: 2/3;
      justify-self: start;
  align-self: center;
  padding-left: 20px;

}

.pr-user-info{
grid-column:2/3 ;
    grid-row:3/4;
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;

  /* border: 1px solid white; */
  display: grid;
  grid-template-columns: auto 80%;
  grid-template-rows: 50% 50%;
  /* max-width: 500px; */
  /* height: 100px; */
  /* column-gap: 20px; */

}

.pr-video-info{
    grid-column:2/3 ;
    grid-row:4/5;
}

.pr-video-info-news{
  grid-column:2/4 ;
    grid-row:4/5;
    padding: 10px 0;
}
.pr-video-info h3, .pr-video-info h5{
    margin: 0;
}

.pr-like-dislike {
  /* border: 1px solid white; */

  grid-column: 3/4;
  grid-row: 3/4;
      justify-self: end;
  align-self: center;
}
.pr-like-dislike-comp{
}

.more-to-come {
  color: var(--sec-t);
  text-align: center;
  padding: 25px;
}

.pr-hot-items{
    /* grid-column:2/3 ;
    grid-row:5/6; */

}



/* Hot Items/Feature Primes */

.pr-hot-items{
    display: grid;
    grid-template-columns: var(--boxcol);
    grid-template-rows: auto auto;
}
.ht-item-title{
  grid-column: 2/3;
  grid-row: 1/2;
}

.nr1-items {
    height: auto;
   grid-column: 2/3;
  grid-row: 2/3;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  /* border: 1px solid black; */
}
.nr-items {
    height: auto;
   grid-column: 2/3;
  grid-row: 2/3;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  /* justify-content: space-between; */
  align-items: center;
  /* border: 1px solid black; */
}
.fp-items
{
  grid-column: 2/3;
  grid-row: 2/3;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* 
.scroller {
    max-width: 390px;
    padding: 10px;
    display: grid;
    grid-auto-flow: column;
    column-gap: 1rem;
    overflow-x: auto;
}

.scroller::-webkit-scrollbar {
    display: none;
}

.scroller-item {
    position: relative;
    width: 100px;
    height: 100px;
    border: 4px solid #151515;
    border-radius: 50%;
}

.scroller-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.scroller-item::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 15px);
    height: calc(100% + 15px);
    background-image:
        linear-gradient(45deg,
            #f09433 0%, #e6683c 25%,
            #dc2743 50%, #6902be 75%,
            #bc1888 100%);
    border-radius: inherit;
    z-index: -1;
} */


.ht-box{
  width: 90%;
  /* max-width: 400px; */
  align-self: center;
   border-radius: var(--brad);
}

.words-scroll{
   overflow: hidden;
   position: relative;
}

.ht-item-pic{
 /* width: 100%;
height: 100%; */
object-fit:cover;
  border-radius: var(--brad);
}
.words-scroll:hover .words-scroll-details{
  opacity: 1;
  height: 50%;
}
.words-scroll-details{
position: absolute;
left: 0;
bottom: 0;
width: 100%;
height: 0%;
background: var(--transp-white);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px 0;
opacity: 0;
    


}

.feat-primes{
     display: grid;
    grid-template-columns: var(--boxcol);
    grid-template-rows: auto auto;
}

.fp-box{
  width: 10%;
  object-fit:fill;
  border-radius: var(--s-brad);

}
.fp-item-pic{
  /* margin: auto; */
}

/* Home Modal */
.home-modal{
  background-image: linear-gradient(to top,
     #5e5e5e 0%, 
     #565656 9.09%, 
     #4f4f4f 18.18%, 
     #474747 27.27%, 
     #404040 36.36%, 
     #3a3a3a 45.45%, 
     #333333 54.54%, 
     #2d2d2d 63.63%, 
     #262626 72.72%, 
     #1f1f1f 81.81%, 
     #181818 90.90%,
     #111111 100%
           );
}

.modal-section{
  display: flex;
  flex-direction: column;
    width: 100%;
  color: var(--sec-t);

}
.modal-home-ad{
  height: 20%;
  background-color: var(--hex-c);
  border-radius: var(--brad);
  padding: 10px;
}
.modal-home-ad-text{
  line-height: none;
}
.modal-home-follows{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal-home-follows-comp{
  display: grid;
  grid-template-columns: 25% 50% 25%;
  /* grid-template-rows: 50% 50%; */
  grid-template-rows: auto auto;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.modal-home-follows-comp-pic{
    grid-column: 1/2;
  grid-row: 1/3;
  width: 90%;
  align-self: center;
  justify-self: center;
}
.modal-home-follows-pic{
  width:100%;
  border-radius: var(--s-brad);
}
.modal-home-follows-name{
  grid-column: 2/3;
  grid-row: 1/2;
  align-self: center;
  font-weight: bold;
  padding-left: 10px;
}
.modal-home-follows-industry{ 
  grid-column: 2/3;
  grid-row: 2/3;
  padding-left: 10px;

  align-self: center;
}


.modal-home-follows-button{
  width:90%;
  height:90%;
  grid-column: 3/4;
  grid-row: 1/3;
  align-self: center;
  justify-self: center;
  border-radius: var(--s-brad);
  display: flex;
justify-content: center;
align-items: center;
}
.modal-home-follows-button-add{
  background-color: var(--qui-c);
}
.modal-home-follows-button-added{
  background-color: var(--sec-c);
}

.modal-home-connections{
}

.modal-section-title{
  justify-self: start;
}

.home-butt{
  background: var(--qui-c);
  color: var(--pri-t);
}
.home-logout-butt{
  background: var(--sec-c);
  color: var(--sec-t);
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home-container{
display: flex;
flex-direction: column;
margin: 2% 10% 2% 0;
padding: 0 16px;
}
.home-container section {
    margin: 0;
    padding: 1rem 0 1.5rem;
}
.pr-video{
    border-radius: var(--brad);
     grid-row:2/3;
      grid-column:2/4 ;
  /* border: 1px solid white; */

  /* Dacast bandaid */
  width: 100%;
height: 210px;

}


}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .home-container{
display: flex;
flex-direction: column;
margin: 2% 10% 2% 0;
padding: 0 16px;
}
.home-container section {
    margin: 0;
    padding: 1rem 0 1.5rem;
}
.pr-video{
    border-radius: var(--brad);
     grid-row:2/3;
      grid-column:2/4 ;
  /* border: 1px solid white; */

  /* Dacast bandaid */
  width: 100%;
height: 24vh;

}

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .home-container{
display: flex;
flex-direction: column;
margin: 2% 10% 2% 0;
padding: 0 16px;
}
.home-container section {
    margin: 0;
    padding: 1rem 0 1.5rem;
}
.pr-video{
    border-radius: var(--brad);
     grid-row:2/3;
      grid-column:2/4 ;
  /* Dacast bandaid */
  width: 100%;
height: 40vh;
}
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .home-container{
display: flex;
flex-direction: column;
/* margin: auto; */
/* padding-left: var(--nav-w); */
margin: 2% 10% 2% 0;
}
.pr-video{
    border-radius: var(--brad);
     grid-row:2/3;
      grid-column:2/4 ;
  /* border: 1px solid white; */

  /* Dacast bandaid */
  width: 100%;
height: 50vh;

}

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .home-container{
display: flex;
flex-direction: column;
/* margin: auto; */
/* padding-left: var(--nav-w); */
margin: 2% 10% 2% 0;
}
.pr-video{
    border-radius: var(--brad);
     grid-row:2/3;
      grid-column:2/4 ;
  /* border: 1px solid white; */

  /* Dacast bandaid */
  width: 100%;
height: 50vh;

}
}