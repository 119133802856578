

.pic {
    width: 50%;
    /* width: 50vw; */
    height: 100vh;
    background: var(--background);
    z-index: 0;
}
.pic2{
    width:40vw
}
.pic3{
    width: 100vw;
    padding-left: 200px;
}

.pic-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 6.25rem; */
}
.background{
    position: absolute;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
opacity: .1;
z-index: 0;
width: 50%;
}
.company{
    width: 100%;
    height: 100%;
    object-fit: fill;
}



.form {
    width: 50vw;
    height: 100vh;
    /* padding: ; */
    background: var(--form-c);
    border-radius: var(--lbrad);
display: flex;
z-index: 1;
overflow-y: scroll;
}
.form-title{
    display: flex;
    flex-direction: column;
}
.auth-logo{
    width: 20%;
    align-self: center;
}
.form-text{
    padding-top: 1rem;
}
.form2{
    width: 60vw;
}
.form-container {
    min-width: 60%;
    max-width: 80%;
    height: auto;
display: flex;
flex-direction: column;
justify-content: space-evenly;
    padding: 6.25rem;
    margin: auto;
}
.auth-form {
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}

/* Style for label and input */
.label-input {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-top:0.625rem ;
    padding-bottom:1.875rem;
}

/* Test */
.validation-wrapper{
    position: relative;
     width: 100%;
      /* padding-top:0.625rem ;
    padding-bottom:1.875rem; */
    height: auto;
    display: flex;
    justify-content: space-between;
}

.fname{
    /* width: 10rem;
    height: 2.5rem;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #585858;
    color: #f9f9f9; */
}
.validation{
    position: absolute;
    top: 0.55rem;
    right: 0.7rem;
    opacity: 0;
    color: #fd4444;
}

@keyframes shake {
    0%, 100% {transform: translateX(0);}
    25% {transform: translateX(8px);}
    75% {transform: translateX(-8px);}
}

/* .fname:valid {border-color: #55d688;} */
.fname:invalid, .lname:invalid {border-color: #fd4444;}
.fname:invalid ~ .validation, .lname:invalid ~ .validation {opacity: 1;}
.fname:invalid, .fname:invalid ~ .validation, .lname:invalid, .lname:invalid ~ .validation {
    animation: shake 0.1635S 0s 3;
}

/* Test */

.label-input label{
    margin:5px;
    color: var(--tri-t);
}
.label-input input, .validation-wrapper input {
    border-radius: var(--inp-brad);
    border: var(--inp-bor);
    /* height: var(--inp-h); */
    font-size: var(--wh4);
    text-indent: var(--inp-ind);
    color: var(--tri-t);
    background: var(--form-c);
    /* width: 100%; */
}
.input1{
    margin-bottom: 25px;
}
.names {
    width: 45%;
}
.label-input select{
     border-radius: var(--inp-brad);
    border: var(--inp-bor);
    height: var(--inp-h);
    font-size: var(--wh4);
    text-indent: 0.5rem;
    color: var(--tri-t);
}

.location{
    display: grid;
    grid-template-columns:auto auto;
    grid-template-rows: auto auto auto;
    column-gap: 50px;
}
.location-label{
    grid-column: 1/3;
}
.location-input{
    padding: 0;
    width: 100%;
/* margin-bottom: 20px; */
}
.input-1{
    padding-bottom: 20px;
}

.pwd {
    /* background-color: #111625 ; */
    border-radius: var(--inp-brad);
    /* box-shadow: var(--bshade); */
    position: relative;
    display: flex;
    width: 100%;
}

.pwd input{
    background: transparent;
       border: var(--inp-bor);
     border-radius: var(--inp-brad);

    color: #5358b9;
    width: 100%;
}

.pwd input:focus{
    outline: none;
}



.pwd .eye, .pwd .eye-slash {
    cursor: pointer;
    color: #5358b9;
    width: 1.2em;
}

.pwd .overlay {
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
    background: #dbdbdb;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    z-index: 0;
    transition: 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pwd .overlaycover {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    right: 0;
}

/* .pwd > div:nth-child(2) {
    position: relative;
} */


.input-group{
    position: relative;
    display: flex;
    align-items: center;
}
.input-group input{
    /* padding: 15px 3rem 15px 15px; */
    width: 100%;
    background: var(--form-c);
    outline: none;
    border: var(--inp-bor);
    border-radius: var(--inp-brad);
    color: var(--tri-t);
    transition: width 0.9s, height 0.9s, border-radius 0.9s;
    font-size: 16px;
}
/* .input-group input::placeholder {
    color: var;
} */
.label-input input:focus{
    border: 1px solid var(--qui-c);
    box-shadow: var(--gl);
}

.input-group .toggle{
    position: absolute;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
    user-select: none;
    z-index: 99;
}
.input-group .ripple{
    background: var(--qui-c);
    display: block;
    position: absolute;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    right: 10px;
    transition: all 0.2s;
    z-index: 1;
}
.pass-strength{
    display: flex;
    align-items: center;
    margin-top: 3px;
}
.strength-label {
    font-size: 12px;
    margin-left: 10px;
}
.strength-percent span {
    display: block;
    height: 100%;
    transition: all 0.4s;
    border-radius: 10px;
}
.weak {
    background: red;
    width: 33%;
}
.average{
    background: #ffd000;
    width: 66%;
}
.strong{
    background: green;
    width: 100%;
}
select{
    background: var(--form-c);
}

.auth-button {
    width: var(--but-w);
    height: var(--but-h);
    border-radius: var(--but-brad);
    border: var(--but-bor);
    margin: var(--but-mar);
    color: var(--sec-t);
    font-size: var(--wh4);
    font-weight: bold;
}
.auth-link{
width: 100%;
align-self: center;
/* height: 100%; */
}
.login-options{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.persist-check-box{
    height: 20px;
    width: 20px;
    margin: 0;
}
.persist-label{
    margin: 0;
    padding-left: 10px;
}
.password-recover-link{
    align-self: end;
}
.social-buttons button{
margin-top: 1rem;
margin-bottom: 1rem;
}
.login {
    margin-top: 1.875rem;
    background-color: var(--pri-c);
    
}
.social-text{
/* padding-top: 20px; */
}
.apple{
    background-color: #1E1F20;
}
.google{
    border: 0.125rem solid var(--outline);
    background-color: #FFFFFF;
    color: var(--pri-t);
}
.other-button{
    background-color: var(--sec-c);
    /* width: 50%; */
}
.center-item {
    align-self: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
.content {
    /* position: absolute; */
    align-self: center;
width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 6.25rem; */
    z-index: 1;
}

.form-title{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
}
    .auth-logo{
    display: none;
}

    .form {
    width: 100vw;
    height: 20%;
    /* padding: ; */
    background: var(--form-c);
    border-radius: var(--tbrad);
display: flex;
z-index: 1;
overflow-y: scroll;
max-height:100%;
padding-bottom: 60px;
}

.pic {
width: 100%;
height: 60%;
background: var(--background);
z-index: 0;
margin: auto;
padding-top: 50px;
padding-bottom: 50px;
margin-left: auto;
margin-right: auto;


}

.form-container {
    min-width: 60%;
    max-width: 80%;
    height: auto;
display: flex;
flex-direction: column;
justify-content: space-evenly;
    padding: 0;
    margin: auto;
}

.auth-button {
    width: var(--but-w);
    height: var(--but-h);
    border-radius: var(--but-brad);
    border: var(--but-bor);
    margin: var(--but-mar);
    color: var(--sec-t);
    font-size: var(--wh4);
    font-weight: bold;
}

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .content {
    /* position: absolute; */
    align-self: center;
     width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 6.25rem; */
    z-index: 1;
}

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}