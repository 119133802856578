.view-container{
    height: 100%;
    width: 100%;
    /* background: var(--pri-t); */
    background: #140f00;
    /* background: #2D0A0F; */
        color: var(--sec-t);

        display: flex;
        justify-content: center;
}
.side-video-content{
  margin-top: 3rem;
  padding:0 1rem;
  display: flex;
  flex-direction: column;
  /* TODO: Add the scroll stuff here */
}
.side-view-container{
  padding: 5rem 0;
   height: 100%;
    width: 20%;
    /* background: var(--pri-t); */
    background: #140f00;
    /* background: #2D0A0F; */
        color: var(--sec-t);

        display: flex;
        flex-direction: column;
        justify-content: center;
        /* TODO: MAke dynamic for mobile */
}
.side-view-container-content{
  overflow: scroll;
  overflow-y: scroll;
}

.side-video-content-thumbnail{
  width: 90%;
  max-width: 10vw;
  color: var(--tri-t);
}
.side-video-content-thumbnail-img{
  width: 100%;
}
.side-video-content-info{
  color: var(--tri-t);
  width: 50%;
}
.view-content{
  /* overflow: hidden; */
/* position: fixed; */
/* height: 100vh; */

    margin: auto;
    /* padding: 10% 10%; */
    padding-top: 10%;

  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--sec-t);
}
.view-content-info{
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.catalog-container{
  width: 100%;
}

.catalog-shows{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-auto-flow: row;
}

.video-block-container{
  width: 90%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(6, auto);
  margin: var(--page-mar);
  padding: 7%;
}

.video-block-thumbnail{
  min-height: 200px;
  width: 100%;
  /* height: ; */
background-color: gray;
  border-radius: var(--brad);
}
.video-block-title{}
.video-block-description{}
.video-block-numbers{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.video-block-numbers-date{}
.video-block-numbers-view{}

.view-content-info-user-interactions{
  display: flex;
  width: 50%;
}
.user{
  display: grid;
grid-template-columns: 25% 75%;
grid-template-rows: 50% 50%;
width: 100%;
}
.info-pic{
  /* width: 40%; */
grid-column: 1/2;
grid-row: 1/3;
/* margin: 20px; */
}
.info-name{
 grid-column: 2/3;
grid-row: 1/2;
justify-self: start;
align-self: end;
padding-left: 20px;
}
.info-time{
  grid-column: 2/3;
grid-row: 2/3;
justify-self: start;
align-self: center;
padding-left: 20px;
}

.subscribe-button{
  background-color: var(--tri-c);
  color: var(--sec-t);
}

.subscribed-button{
  background-color: var(--hex-c);
  color: var(--pri-t);
  
}