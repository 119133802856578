.upload-container{
     height: 100vh;
    width: 100%;
           display: flex;
           flex-direction: column;
        justify-content: center;
        align-items: center;
}

.upload-content{
    width: 75%;
    padding: 3rem;

}
.testing-form {
    border: 1px solid black;
    
}