
:root {
    /* Background */
    --background: #000000;
    /* --background: #F7F7F7; */
    --background2: #000000;
    /* --background2: #C4C4C4; */

    /* Form Color */
    --form-c: #ffffff;

    /* Text Colors */
    --pri-t: #020101;
    --sec-t: #ffffff;
    --tri-t: #8F92A1;

    /* Colors */
    --pri-c: #611821;
    --sec-c: #8e202b;
    --tri-c: #BD2932;
    --qua-c: #fffd9b;
    --qui-c: #ddcd76;
    --hex-c: #b9a054;

    /* NavBar Colors */
    --navb-c: #BD2932;
    --foot-c: #939597;

    --transp-white:rgba(255, 255, 255, 0.9);

    --outline: #8F92A1;

    /* Glowing Colors */
    --gl:0 0px 10px 4px var(--qua-c);
    --gl-red:0 0px 10px 4px var(--tri-c);
    --gl-hov:0 0px 10px 10px var(--qui-c);

/* Shade Colors */
    --shade:#8F92A1;
    --bshade: 0 0px 25px 1px #97989e;
    --bshadeclicked: none;
    --p-bshade: 0 0px 25px 1px #611821;
    --p2-bshade: 0 0px 25px 1px #b9a054;
    --tshade: 0 0px 25px 1px #000000;
    --btn-shade:0 0px 25px 1px black;

    /* typography */
    --font: "Inter", sans-serif;

    /* Font Size */
    /* Web Size */
    --wh1: 4.5rem; /*h1*/
    --wh2: 3.5rem; /*h2*/
    --wh3: 2.5rem; /*h3*/
    --wh4: 1.5rem; /*h4*/
    --wh5: 1rem; /*h5*/
    --wh6: 0.75rem; /*h6*/

    /* Text Weight */
    --wlabel: 0.75rem; /*Labels*/
    --wtb: 1.25rem; /*Text Big*/
    --wt: 1rem; /*Text Weight*/
       --wtg: 1rem; /*Text Gray*/
    --wts: 0.75rem; /*Gray Text Weight*/
    --wb: 1rem; /*button text weight*/


    /* Mobile/ Tablet */
    --mh1:2.938rem; /*h1*/
    --mh2: 2.5rem; /*h2*/
    --mh3: 1.438rem; /*h3*/
    --mh4: 1.375rem; /*h4*/
    --mh5: ; /*h5*/
    --mh6: ; /*h6*/

    /* Text Weight */
    --mlabel: 0.75rem; /*Labels Weight*/
    --mtb: 1.25rem; /*Big Text Weight*/
    --mt: 0.75rem; /*Text Weight*/
    --mtg: 1rem; /*Text Gray Weight*/
    --mts: 0.75rem; /**/
    --mb: 1.5rem; /*Button Text Weight*/


    /* bold */
    --bold: bold;

    /* Grid Layout */
    --wcol: 10% auto 10%;
    --mcol: 5% auto 5%;

    --boxcol: 6% auto 6%;

    /* Grid Gaps */
    --cgap:20px;
    --rgap:10px;
    /* Section Padding */
    
    /* Section Margins */
    --page-mar: 2% 10% 2% 0;


    /* Border Radius */
    --brad:2rem 2rem 2rem 2rem;
    --lbrad: 2rem 0 0 2rem;
    --rbrad: 0 2rem 2rem 0;
    --tbrad: 2rem 2rem 0 0;
    --bbrad: 0 0 2rem 2rem;

    /* Section I think */
    --s-brad:1rem 1rem 1rem 1rem;
    --s-lbrad: 1rem 0 0 1rem;
    --s-rbrad: 0 1rem 1rem 0;
    --s-tbrad: 1rem 1rem 0 0;
    --s-bbrad: 0 0 1rem 1rem;

    /* NAVE BAR WIDTH */
    --nav-w:15.625rem;
    --nav-w-plus-padding:16.625rem;
    --nav-hw:30.438rem;

    --nav-per:12%;
    --home-per:60%;
    --mod-w-per:20%;

    --navbutpad:0.5rem;

    /* MODAL  */
    --mod-w:30.438rem;
    --mess-mod-w: 45%;

    /* Button */
    --but-h:4rem;
    --but-w:100%;
    --but-wh:25%;
    --but-brad:0.625rem;
    --but-bor:0 solid var(--outline);
    --but-mar:0.25rem 0.125rem;
    /* laptop Screen */
        --but-h-l:3rem;
--but-mar-l:0.25rem 0.125rem;

    /* Inputs */
    --inp-h:3rem;
    --inp-w:100%;
    --inp-brad: 0.313rem;
    --inp-bor:1px solid var(--outline);
    --inp-ind: 1rem;
        /* laptop Screen */
    --inp-h-l:3rem;
    --inp-ind-l: 1rem;

    /* React-Icons Sizing */
    --r-i-Big: 2.5rem;

--transition: 0.4s ease;

    /* Test */
    --test: 90%;
}

* {
    transition: all 0.5s ease-in-out;
    -webkit-transition: .2s ease;
    box-sizing: border-box;
}

body {
    background: var(--background);
    margin:0;
    padding:0;
}
h1, h2, h3, h4, h5, h6{
    margin: 0;
}
h1{
    font-size: var(--wh1);
}
button {
    cursor: pointer;
    /* This interfers with video button */
     width: var(--but-w);
    height: var(--but-h);
    border-radius: var(--but-brad);
    border: var(--but-bor);
    margin: var(--but-mar);
    color: var(--sec-t);
    font-size: var(--wh4);
    font-weight: bold;
    user-select: none;
}
button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  color: var(--tri-t);

}
button[type="submit"] {
  background: var(--tri-c);
  color: var(--pri-t);
  border-radius: var(--but-brad);
  border: none;
}
.test-modal-button{
    position: fixed;
}
/* unvisited link */
a:link {
  color:var(--sec-t);
}

/* visited link */
a:visited {
  color: var(--sec-t);
}

/* mouse over link */
/* a:hover {
  color: hotpink;
} */

/* selected link */
a:active {
  color: var(--qui-c);
}
textarea{
    border: none;
    resize: none;
    outline: none;
    font-family: var(--font);
}
textarea::placeholder, input::placeholder{
    color: var(--tri-t);
}
input{
    outline: none;
}
/* change naming for this */
.page {
    width: 100%;
    /* height: 100%; */
    background: var(--background);

    /* The reason for page mess up */
    display: flex;
    flex-direction: row;
    
    /* margin: auto; */
    padding-left: calc(var(--nav-w) + 6%);
    /* padding-left: var(--nav-w); */
}
.page-button{
    width: var(--but-w);
    height: var(--but-h);
    border-radius: var(--but-brad);
    border: var(--but-bor);
    margin: var(--but-mar);
    color: var(--sec-t);
    font-size: var(--wh4);
    font-weight: bold;
}

button:hover {


}
button:active {

    box-shadow:var(--bshade);
  transform: translateY(4px);
}
.clickable:hover {
  cursor: pointer;

}
.clickable:active {
      box-shadow:var(--bshadecicked);
  transform: translateY(4px);
}
.bigger:hover {
        box-shadow:var(--tshade);
  transform: scale(1.1, 1.1);
}

/* shading and glowing */
.pad-ring {

}

.title-space{
    padding-bottom: 1.25rem;
}

.glow-ring {
      border-radius: var(--brad);
    background: var(--form-c);
    box-shadow:var(--gl);
}
.hovering:hover{
    border-radius: var(--brad);
    background: var(--form-c);
    box-shadow:var(--gl-hov);
}
.p-con-shade{
    box-shadow: var(--p-bshade);
}
.p2-con-shade{
    box-shadow: var(--p2-bshade);
}
.con-shade{
    box-shadow: var(--bshade);
}
.prime-m-text{
    color: var(--sec-t);
}
.connected-btn-shade{
    box-shadow: var(--gl-red);
}
.connected-prime-btn-shade{
box-shadow: var(--gl);
}
.btn-shade{
box-shadow: var(--btn-shade);
}
.item-shade{
box-shadow:var(--tshade);
}
.p-item-shade{
box-shadow:var(--p-bshade);
}
.small-border{
    border-radius: var(--s-brad);
}
.bold{
    font-weight: var(--bold);
}

.square-container{
position: relative;
  /* width: 80%; */
  /* Change the width inside of the div specified with percent for object depending on the object */
}
.square-container:after{
  content: "";
  display: block;
  padding-bottom: 100%;
}
.square-container-contents{
position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--s-brad);
  object-fit: cover;
}
.classLink-user h1,
.classLink-user h2
.classLink-user h3
.classLink-user h4
.classLink-user h5
.classLink-user h6{
    color: var(--pri-c);
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.hide {
    display: none;
}
.plyr__video-wrapper{
    display:none;
}
/* #video-wrapper{

} */
/* .wrapper{
    padding: 0;
    margin: 0;
    width: 100%;
    display: none;

} */
/* .container{
    margin:0;
    padding:0;
    position: relative;
} */
 /* iframe {
    width: 100%;
    height: 100%;
    
 } */

  /* iframe > html {
    width: 100%;
    height: 100%;
    display: none;
    
 } */

 /* iframe > html >  */
 /* #body{
    background-color: orange;
font-family: 'Rubik', sans-serif;
display: flex;
align-items: center;
justify-content: center;
 } */

 /* #body div.wrapper{
    --video-aspect-ratio: 1;
--padding: 2em;
box-sizing: border-box;
width: 100%;
padding: var(--padding);
margin-top: -6px;
margin-left: auto;
margin-right: auto;
max-width: 1280px;
 } */



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    h1 {
        font-size: var(--mh1);
        font-weight: Bold;
    }
    
    h2 {
        font-size: var(--mh2);
        font-weight: Bold;
    }
    
    h3 {
        font-size: var(--mh3);
        font-weight: Bold;
    }
    
    h4 {
        font-size: var(--mh4);
        font-weight: Bold;
    }
    
    h5 {
        font-size: var(--mh5);
        font-weight: Bold;
    }
    h6 {
        font-size: var(--mh6);
        font-weight: normal;
    }
    
    p,
    .footer-anchor {
        font-size: var(--mt);
        font-weight: Bold;
    }
    
    .text-big {
        font-size: 1.625rem;
        font-weight: lighter;
    }
    
    .text-gray {
        font-size: var(--wtg);
        color: var(--tri-t);
        font-weight: normal;
    }
    .page {
    width: 100%;
    /* height: 100%; */
    background: var(--background);
    /* The reason for page mess up */
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-top: 30%;
}
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .container {
        display: grid;
        grid-template-columns: var(--mcol);
    }
    .page {
    width: 100%;
    /* height: 100%; */
    background: var(--background);
    /* The reason for page mess up */
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-top: 30%;
}
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
        .container {
        display: grid;
        grid-template-columns: var(--mcol);
    }
    .page {
    width: 100%;
    /* height: 100%; */
    background: var(--background);
    /* The reason for page mess up */
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-top: 30%;
}
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .container {
        display: grid;
        grid-template-columns: var(--wcol);
    }
    /* Testing button sizes and changes */
    button{
        height: var(--but-h-l);
    font-size: var(--wh4);
    }

    input {
    height: var(--inp-h-l);
    }
    .page {
    width: 100%;
    /* height: 100%; */
    background: var(--background);

    /* The reason for page mess up */
    display: flex;
    flex-direction: row;
    
    /* margin: auto; */
    padding-top: 0;
    padding-left: calc(var(--nav-w) + 6%);
    /* padding-left: var(--nav-w); */
}

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    /* Font Sizes */

    .test_black{
        color:black;
        
    }

    h1 {
        font-size: var(--wh1);
        font-weight: Bold;
        line-height: 5rem;
    }
    
    h2 {
        font-size: var(--wh2);
        font-weight: Bold;
        line-height: 4rem;
    }
    
    h3 {
        font-size: var(--wh3);
        font-weight: Bold;
        line-height: 3rem;
    }
    
    h4 {
        font-size: var(--wh4);
        font-weight: Bold;
        line-height: 2rem;
    }
    
    h5 {
        font-size: var(--wh5);
        font-weight: normal;
        line-height: 2rem;
    }
    h6 {
        font-size: var(--wh6);
        font-weight: normal;
        line-height: 0.75rem;
    }
    
    p,
    .footer-anchor {
        font-size: var(--wt);
        font-weight: Bold;
        line-height: 1.5rem;
    }
    
    .text-big {
        font-size: 1.625rem;
        font-weight: lighter;
        line-height: 2rem;
        /* font-family: "Roboto", sans-serif; */
    }
    
    .text-gray {
        font-size: var(--wtg);
        color: var(--tri-t);
        font-weight: normal;
    }
    .text-pri{
        color: var(--pri-t);
    }
    .text-sec{
        color: var(--sec-t)
    }
    
    a {
        text-decoration: none;
    }
}
    /* background-image: linear-gradient(to top,
     #fffd9b 0%, 
     #f9f494 9.09%, 
     #f3eb8d 18.18%, 
     #ece286 27.27%, 
     #e6d97f 36.36%, 
     #e0d179 45.45%, 
     #daca73 54.54%, 
     #d4c26d 63.63%, 
     #cdb967 72.72%, 
     #c7b160 81.81%, 
     #c0a85a 90.90%,
     #b9a054 100%
           ); */