/* Settings Page */
.settings-container{
    display: flex;
flex-direction: column;
/* padding-left: var(--nav-w); */
margin: 2% 0;
width: 50%;
min-width: 50%;
height: 100%;
}
.settings-page-title{
    padding-bottom: 25px;
}
.settings-profile-form{
    padding-bottom: 200px;
    width: 100%;
    height: auto;

}
.settings-user-pic-group{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 50% 50%;
}
.settings-user-pic-pic{
    grid-area: 1/1/3/2;
    width: 90%;
    border-radius: var(--brad);
    justify-self: start;
    align-self: center;
    cursor: pointer;
}
.settings-user-pic-label{
    grid-area: 1/2/2/3;
    color: var(--tri-t);
    justify-self: start;
    align-self: center;
}
.settings-user-pic-button{
    grid-area: 2/2/3/3;
    justify-self: start;
    align-self: center;
    background-color: var(--qui-c);
    color: var(--pri-t);
}
.settings-profile-pic-change{}
.settings-user-info{}
.settings-user-info-group{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    padding-top: 50px;
    column-gap: 50px;

}
.settings-user-info-button{
    margin-top: 50px;
    background-color: var(--tri-c);
}
input:hover, select:hover{
    /* box-shadow: var(--gl);
    transform: scale(1.1, 1.1); */
}

.setting-modal{
background-image: linear-gradient(to top,
     #000000 0%, 
#0d0d0d 9.09%, 
#161616 18.18%, 
#1d1d1d 27.27%, 
#242424 36.36%, 
#2b2b2b 45.45%, 
#323232 54.54%, 
#393939 63.63%, 
#414141 72.72%, 
#4a4a4a 81.81%, 
#525252 90.90%, 
#5b5b5b 100%
           );
}

.settings-box-container {
    justify-content: start;
    color: var(--sec-t);
}
.modal-setting-container{
   padding-top: 100px;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-around;
}
.modal-setting-title{}
.modal-setting-button{
    /* color: var(--sec); */
    display: flex;
    align-items: center;
    width: 100%;
    /* padding:50px 0; */
    /* border: 1px solid white; */
}
.setting-button-icon-box{
    /* width: 200px; */
        border: 1px solid white;
        border-radius: var(--brad);
    margin-right: 25px;
}
.setting-button-icon-box-selected{
        background-color: var(--sec-t);
       
}


.setting-button-icon{
    color: var(--sec-t);
    font-size: 3rem;
        padding: 0.5rem;
}
.setting-button-icon-selected{
    color: var(--pri-t);

}
.setting-button-text{}
.setting-button-text:hover{
    
}
.modal-setting-button:hover > .setting-button-icon-box {
    box-shadow: var(--gl);
    border: 1px solid var(--qui-c);
    
}
.modal-setting-button:hover  .setting-button-text {
    color: var(--qua-c);
    
}
.modal-setting-button:hover  .setting-button-icon {
    color: var(--qua-c);
    
}