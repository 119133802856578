.feed-items {
    width: 100%;
}

.post-reply-container{
     display: grid;
    grid-template-columns: 6% 1fr 1fr 1fr 6%;
    /* grid-template-columns: var(--boxcol); */
    grid-template-rows: repeat(3, auto);
}

.post-item-container {
    display: grid;
    grid-template-columns: var(--boxcol);
    grid-template-rows: repeat(5, auto);
}

.post-item-user-info {
    grid-column: 2/3;
    grid-row: 1/2;
}
.post-item-user-info-under {
    grid-column: 2/3;
    grid-row: 3/4;
}
.item-user-status {
     grid-column: 2/3;
    grid-row: 3/4;
    padding-top: 20px;


}
.item-user-content {
     grid-column: 2/3;
    grid-row: 2/3;
    padding-top: 20px;
}

.post-photo-container{
      display: grid;
    grid-template-columns: var(--boxcol);
    grid-template-rows: repeat(5, auto);
}
.post-video-container{
         display: grid;
    grid-template-columns: var(--boxcol);
    grid-template-rows: repeat(5, auto); 
}

.post-episode-container{
             display: grid;
    grid-template-columns: 6% auto auto 6%;
    /* grid-template-columns: var(--boxcol); */
    grid-template-rows: repeat(5, auto); 
}
.post-music-container{
             display: grid;
    grid-template-columns: 6% auto auto 6%;
    /* grid-template-columns: var(--boxcol); */
    grid-template-rows: repeat(5, auto); 
}
.post-store-container{
     display: grid;
    grid-template-columns: 6% auto auto 6%;
    /* grid-template-columns: var(--boxcol); */
    grid-template-rows: repeat(5, auto); }


.post-interaction{
    display: flex;
    flex-direction: column;
    grid-column: 2/3;
    grid-row: 4/5;
}

.like-dislike-number {
    display: flex;
    justify-content: space-around;
}

.post-like-dislike{
    display: flex;
justify-content: space-between;
    
}
.post-video{
padding-left: 25px;
padding-right: 25px;
align-items: center;
width: 200px;
justify-content: space-between;
}

.post-like-dislike-left{
    width: 30%;
display: flex;
justify-content: space-around;
}
.post-like-dislike-right{
        width: 25%;
display: flex;
justify-content: flex-end;

}
.post-comment{
    width: 100%;
    display: flex;
    align-items: center;
justify-content: center;
color: var(--pri-t);
}
.post-comment-light{
     width: 100%;
    display: flex;
    align-items: center;
justify-content: center;
/* color: var(--sec-t); */
color: var(--sec-t);
}
.comment-number-light{
    margin-left: 20px;
}
.post-share{
    display: flex;
    align-items: center;
justify-content: center;
    width: 100%;
}
.post-like-btn{
    width: 100%;
    border-radius: var(--brad);
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}
.heart{
    color: var(--tri-c)
}
.heart-number{
    color: var(--tri-c)
}

.skull{
    color: var(--hex-c);
}
.skull-number{
    color: var(--hex-c);
}
.comment-icon{
    color:var(--pri-t)
}
.comment-icon-light{
    color:var(--sec-t)
}
.post-btn-space {
    padding-right: 10px;
}
.post-light {
    color: var(--sec-t)
}

.share-text{
    color: var(--pri-t);
}
.share-socials{
    /* border: 1px solid black; */
    padding: 20px;
}
.share-socials-item{
    /* border: 1px solid black; */
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: var(--r-i-Big);
    height: auto;

}
.share-socials-item:hover{
    background-color: var(--qua-c);
    cursor: pointer;
}
.share-socials-facebook{
    color: #4267B2;
}
.share-socials-twitter{
    color: #1DA1F2;
}

.share-socials-instagram{
/* color: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);  */
width: var(--r-i-Big);
}


.share-socials-linkedIn{
    color: #0072b1;
}
.share-socials-email{}
.share-socials-text{
    color: #43CC47;
}


/* Comment Box */
.comment-container{
    /* overflow: scroll; */
}

.comment-box{
display: flex;
flex-direction: column;
width: 100%;
/* max-width: 95%; */
margin: 50px auto;
/* background: var(--form-c); */
/* border-radius: var(--brad); */
/* box-shadow: var(--bshade); */
/* padding: 14px; */
max-height: 100%;
}
/* .comment-box.modified {
  max-height: unset;
} */
.comment-box-label {
    /* border: 1px solid black; */
/* visibility: hidden; */
}

.comment-box-header-user{
    display: flex;
    align-items: center;
    /* width: 25%; */
    /* border: 1px solid black; */
}
.comment-box-avatar {
    /* width: 25%; */
margin-right: 10px;
    width: 10%;
    border-radius: var(--brad);
}
.comment-box-avatar-name{
    color: var(--sec-t);
}

/* .comment-box.expanded .comment-box-header {
  transform: translateY(10px);
  opacity: 1;
  visibility: visible;
} */

.comment-box-field {
    /* border: 1px solid black; */
/* align-self: center; */
    color: var(--sec-t);
    background-color: #140f00;
  /* transition: transform var(--transition); */
  font-size: 18px;
  width: 100%;
  outline: 1px solid var(--tri-t);
  padding: 20px;
  min-height: 80px;
  /* transform: translateY(-32px); */
  line-height: 1;
    box-shadow: var(--bshade);

}

.post-button {
    width: var(--but-wh);
    height: var(--but-h);
    border-radius: var(--but-brad);
    border: var(--but-bor);
    margin: var(--but-mar);
    color: var(--sec-t);
    font-size: var(--wh4);
    font-weight: bold;
}
.comment-cancel{
    border: var(--but-bor);
    color: black;
    background-color: transparent;
    /* border-radius: ; */
}
.comment-box-actions {
    /* display: flex;
    justify-content: end;
    width: 100%; */
width: 100%;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  margin-top: 50px;
  /* opacity: 0; */
  /* transition: opacity var(--transition) 0.2s; */
}

/* .comment-box.expanded .comment-box-actions {
  opacity: 1;
} */


.comment-box-actions button {
    margin-left: 6px;
  font-size: 14px;
  padding: 12px;
  border: none;
  line-height: 1;

}

.comment-box-actions .comment-cancel {
  background: none;
}

.comment-box.expanded .comment-box-field {
  transform: translateY(40px);
}
.prev-comment{
    /* margin-top: 20px; */
    /* padding: 20px; */
    /* border-radius: var(--brad); */
    /* box-shadow: var(--bshade); */
}
.prev-comment-comments{
    height: auto;
    overflow-y: scroll;
    /* padding-bottom: 50%; */
}

.comment-box-reply-container{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}
.comment-box-user{
    display: grid;
    grid-template-columns: 10% 2% auto;
    grid-template-rows: repeat(6, auto);
    height: auto;
    /* max-height: 100px; */
    margin-bottom: 20px;
}
.comment-box-user-pic{
    border-radius: var(--brad);
    grid-column: 1/2;
    grid-row: 1/4;
    width: 100%;
    /* height: 100px; */
    align-self: center;
    justify-self: center;
    object-fit: fill;

}
.comment-box-user-name{
    color: var(--sec-t);
grid-column: 3/4;
    grid-row: 1/2;
    align-self: center;
}
.comment-box-user-date{
grid-column: 3/4;
    grid-row: 2/3;
    align-self: start;
}
.comment-box-user-comment{
    color: var(--sec-t);

grid-column: 3/4;
    grid-row: 3/4;
}
.comment-box-user-toggle{
    grid-column: 3/4;
    grid-row: 4/5;
}
.comment-box-user-like-dislike{
    grid-column: 3/4;
    grid-row: 5/6;
}

.comment-box-reply{
    display: flex;
flex-direction: column;
align-self: center;
width: 90%;
max-height: 100%;
}
/* .comment-box.modified {
  max-height: unset;
} */
.comment-box-label {
    /* border: 1px solid black; */
/* visibility: hidden; */
}
.comment-box-user-reply-toggle{
    grid-column: 3/4;
    grid-row: 4/5;
    align-self: start;
}

.comment-box-reply-field {
    /* border: 1px solid black; */
align-self: center;
    color: var(--sec-t);
    background-color: #140f00;
  /* transition: transform var(--transition); */
  font-size: 18px;
  width: 100%;
  outline: 1px solid var(--tri-t);
  /* padding: 20px; */
  min-height: 80px;
  /* transform: translateY(-32px); */
  line-height: 1;
    box-shadow: var(--bshade);

}
.comment-reply-box-actions{
    /* display: flex;
    justify-content: end;
    width: 100%; */
width: 100%;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  margin-top: 50px;
  /* opacity: 0; */
  /* transition: opacity var(--transition) 0.2s; */
}

/* .comment-box.expanded .comment-box-actions {
  opacity: 1;
} */


.comment-reply-box-actions button {
    margin-left: 6px;
  font-size: 14px;
  padding: 12px;
  border: none;
  line-height: 1;

}