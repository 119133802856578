/* consider putting this is the app.css */
*, *::before, *::after {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.video-container {
    position: relative;
    /* width: 90%; */
    /* max-width: 1000px; */
    /* height: 75%; */
    display: flex;
    justify-content: center;
    margin-inline: auto;
    background-color: black;
}

.video-container.theater, 
.video-container.full-screen {
    max-width: initial;
    width: 100%;
}
.video-container.theater {
    max-height: 90vh;
}
.video-container.full-screen {

    max-height: 100vh;
}


video {
    width: 100%;
}

.video-controls-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    z-index: 100;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

.video-controls-container::before {
content: "";
position: absolute;
bottom: 0;
/* background: linear-gradient(to top, rgba(0,0,0, .75), transparent); */
background: linear-gradient(to top, var(--pri-c), transparent);
background: linear-gradient(to top, var(--qua-c), transparent);
width: 100%;
aspect-ratio: 6/1;
z-index: -1;
pointer-events: none;
}

.video-container:hover .video-controls-container, 
.video-container:focus-within .video-controls-container, 
.video-container.paused .video-controls-container {
    opacity: 1;
}

.video-controls-container .controls {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    padding: .25rem;
    align-items: center;
color: var(--qua-c);
color: var(--tri-c);
}

.video-controls-container .controls button{
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    height: 30px;
    width: 30px;
    font-size: 1.1rem;
    cursor: pointer;
    opacity: .85;
    transition: opacity 150ms ease-in-out;
}

.video-controls-container .controls button:hover{
opacity: 1;
}

.video-container.paused .pause-icon {
    display: none;
}

.video-container:not(.paused) .play-icon{
    display: none;
}

.video-container.theater .tall {
    display: none;
}

.video-container:not(.theater) .wide{
    display: none;
}

.video-container.full-screen .open {
    display: none;
}

.video-container:not(.full-screen) .close{
    display: none;
}

.volume-high-icon,
.volume-low-icon,
.volume-muted-icon {
    display: none;
}

.video-container[data-volume-level="high"] .volume-high-icon {display: block}
.video-container[data-volume-level="low"] .volume-low-icon {display: block}
.video-container[data-volume-level="muted"] .volume-muted-icon {display: block}

.volume-container {
    display: flex;
    align-items: center;
}

.volume-slider {
    width: 0;
    transform-origin: left;
    transform: scaleX(0);
    transition: width 150ms ease-in-out, transform 150ms ease-in-out;
}
.volume-container:hover .volume-slider,
.volume-slider:focus-within {
    width: 100px;
    transform: scaleX(1);
}

.duration-container{
    display: flex;
    align-items: center;
    gap: .25rem;
    flex-grow: 1;
}

.video-container.captions .captions-btn{
    border-bottom: 3px solid white;
}

.video-controls-container .controls button.wide-btn {
    width:50px;
}

.timeline-container {
    height: 7px;
    margin-inline: .5rem;
    cursor: pointer;
    display:flex;
    align-items: center;
}


.timeline {
    /* for now */
    background-color: rgba(100, 100, 100, .5);
    height: 3px;
    width: 100%;
    position: relative;
}

.timeline::before {
content: "";
position: absolute;
left: 0;
top: 0;
bottom: 0;
right: calc(100% - var(--preview-position) * 100%);
/* for now */
background-color: rgb(150, 150, 150);
}

.timeline::after {
content: "";
position: absolute;
left: 0;
top: 0;
bottom: 0;
right: calc(100% - var(--progress-position) * 100%);
/* for now */
background-color: red;
}

.timeline .thumb-indicator {
    --scale: 0;
    position: absolute;
    transform: translateX(-50%) scale(var(--scale));
    height: 200%;
    top: -50%;
    left: calc(var(--progress-position)*100%);
    background-color: red;
    border-radius: 50%;
    transition: transform 150ms ease-in-out;
    aspect-ratio: 1/1;
}

.timeline .preview-img {
    position: absolute;
    height: 80px;
    aspect-ratio: 16/9;
    top: -1rem;
    transform: translate(-50%, -100%);
    left: calc(var(--preview-position) * 100%);
    border-radius: .25rem;
    border: 2px solid white;
    display: none;
}
.thumbnail-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.video-container.scrubbing .thumbnail-img {
    display: block;
}

.video-container.scrubbing .preview-img,
.timeline-container:hover .preview-img {
    display: block;
}

.video-container.scrubbing .timeline::before,
.timeline-container:hover .timeline::before {
    display: block;
}

.video-container.scrubbing .thumb-indicator,
.timeline-container:hover .thumb-indicator{
    --scale: 1;
} 

.video-container.scrubbing .timeline,
.timeline-container:hover .timeline {
    height: 100%;
}
