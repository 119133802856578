/* User Page */
.user-container{
   display: flex;
flex-direction: column;
margin:var( --page-mar); 
/* width: var(--home-per); */
/* width: 50%; */
/* min-width: 50%; */
height: 100%;
}
.user-true{
width: 100%;
padding-right: calc(var(--mod-w-per) + 2%);

}
.user-false{
width: 100%;
/* padding-right: 10%; */
}
.user-container section {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 3rem 0;
    height: fit-content;
}
.user-container section:hover {
        box-shadow:var(--tshade);
  transform: scale(1.1, 1.1);
}
.feed-section-wrapped{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.feed-section-no-wrap{
  display: flex;
  flex-direction: column;
}
.wrapping{
  width: 45%;
  /* margin-right:25px; */
}
.no-wrapping{
  width: 100%;
}

/* User Modal */
.modal {
    display: grid;
    grid-template-columns: 5% auto 5%;
    grid-template-rows: 5% auto 5%;
    /* border: 1px solid black; */
    height: 100vh;
    /* mod width too big for laptop */


    /* TEST */
    width: var(--mod-w-per);


    /* width: var(--mod-w); */
    /* width: 20rem; */
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    /* background-color: var(--form-c); */

    background-size: 250% 200%;
    animation: movingGradient 5s linear infinite alternate;
    color: var(--pri-t);
    border-radius: var(--lbrad);
    overflow-x: hidden ;
}
.verified-modal{
        color: var(--sec-t);
background-image: linear-gradient(to top,
     #fffd9b 0%, 
     #f9f494 9.09%, 
     #f3eb8d 18.18%, 
     #ece286 27.27%, 
     #e6d97f 36.36%, 
     #e0d179 45.45%, 
     #daca73 54.54%, 
     #d4c26d 63.63%, 
     #cdb967 72.72%, 
     #c7b160 81.81%, 
     #c0a85a 90.90%,
     #b9a054 100%
           );
}

/* Modal Contents */
.modal-container{
    grid-column: 2/3;
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.modal-user-container{
  justify-content: space-between;
}

/* items in Modal */
/* notifaction and profile pic */
.modal-t-boxes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-start;
    width: 100%;
}
.top-boxes{
  border-radius: var(--s-brad);
  margin: 0 15px;
width: 25%;
}

/* notifaction box */
.noti-box{
background-color: var(--pri-c);
display: flex;
justify-content: center;
}
.noti-num {
    /* border: 1px solid black; */
    text-align: center;
    color: var(--sec-t);
}
.prof-pic{
  /* border: 1px solid white; */
  /* width: 20%; */
}
.prof-pic-pic{
 object-fit: cover;
border-radius: var(--s-brad);
}

/* Modal Profile Picture and info*/
.modal-prof-info{
  display: flex;
  flex-direction: column;
width: 75%;
  border-radius: var(--brad);
  align-items: center;

}
.modal-prof-pic{
    /* maybe make this smaller for the profile picture in the modal */
    width: 50%;
    /* 50% works for laptop */
  border-radius: var(--brad);
}

.prof-info-text{
    text-align: center;
}
.prof-info-numbers{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.prof-work-with{
width: 100%;
}
.work-with-title{
  font-weight: var(--bold);
}
.prof-connected{
width: 100%;
}
.prof-connected-users{
display: grid;
  grid-template-columns: repeat(4, 25%);
  /* gap: 10px; */
  align-items: center;
  justify-items: center;
  object-fit: fill;
}

.prof-connected-users-img-container{
margin: 15px 0;
width: 75%;
}

.prof-connected-users-img{
  object-fit:cover;
border-radius: var(--s-brad);
}


/* Buttons for modal */
.modal-user-buttons{
  display: flex;
  flex-direction: row;
}

.modal-connect-button{
  background-color: var(--sec-c);
   width: var(--but-w);
    height: var(--but-h);
    border-radius: var(--but-brad);
    border: var(--but-bor);
    margin: 0 0.5rem;
    color: var(--sec-t);
    font-size: var(--wh4);
    font-weight: bold;
}

.modal-mail-button{
background-color: var(--sec-t);
color: var(--sec-c);

/* width: var(--but-w); */
width: 5rem;
height: var(--but-h);
border-radius: var(--but-brad);
border: 2px solid var(--sec-c);;
/* margin: var(--but-mar); */
margin: 0 0.5rem;
font-size: var(--wh4);
font-weight: bold;
}
/* Feed Reply */
.feed-reply-heading{
   grid-column: 2/4;
  grid-row: 1/2;
}
.feed-reply-text{
  grid-column: 2/4;
  grid-row: 2/3;

}
.feed-reply-attachment{
grid-column: 2/3;
  grid-row: 3/4;
padding: 20px;
display: flex;
justify-content: space-between;
background: rgba( 247, 247, 247, 0.25 );
box-shadow: var(--bshade);
border-radius: var(--brad);
border: 1px solid rgba( 255, 255, 255, 0.18 );

}
.feed-reply-post{
  grid-column: 3/5;
  grid-row: 3/4;

}
/* Feed Text */
/* Feed Photos */
.feed-post-text {
     grid-column: 2/3;
    grid-row: 3/4;
    padding: 20px 0 ;


}
.feed-photos-gallery{
  max-width: 100%;
  width: auto;
  grid-area: 4/2/5/3;
  display: grid;
  grid-template-columns: 1fr var(--cgap) .5fr var(--cgap) .5fr;
  grid-template-rows: .5fr var(--rgap) .5fr;
}
.feed-photos-images{
  
  width: 100%;
  height: 100%;
  border-radius: var(--brad);
  object-fit: cover;
  
}
.photo1{
  grid-area: 1/1/4/2;
}
.photo2{
  grid-area: 1/3/2/4;
}
.photo3{
  grid-area: 1/5/2/6;
}
.photo4{
  grid-area: 3/3/4/4;
}
.photo5{
  grid-area: 3/5/4/6;
}

.feed-post-video{
  /* grid-area: 4/2/5/3; */
}
.feed-post-video-title {
      grid-column: 2/3;
    grid-row: 3/4;
    padding-top: 20px;
}
.feed-post-episode-title{
     grid-column: 2/4;
    grid-row: 2/3;
    /* padding-top: 20px; */
    align-self: center;
}
.feed-post-episode-video{
  grid-column: 2/4;
    grid-row: 1/2;
}
.feed-post-episode-button{
  /* padding: 20px; */
  width: 100px;
  border: var(--but-bor);
margin: var(--but-mar);
  border-radius: var(--brad);
  background-color: var(--qua-c);
  color:var(--pri-t);
  align-self: center;
  justify-self: end;
  grid-column: 3/4;
grid-row: 3/4;
}

.feed-post-music-music{
   grid-column: 2/4;
    grid-row: 1/2;
}
.feed-post-music-info{
   grid-column: 2/4;
grid-row: 2/3;
  display: grid;
  grid-template-columns: 45%  10% 45%;
  grid-template-rows:repeat(2, 50%) ;
}
.feed-post-music-info-title{
grid-column: 1/2;
grid-row: 1/2;
justify-self: start;
align-self: center;

}
.feed-post-music-info-album{
grid-column: 3/4;
grid-row: 1/3;
justify-self: center;
align-self: center;
width: 100%;
}
.feed-post-music-info-artist{
grid-column: 1/2;
grid-row: 2/3;
justify-self: start;
align-self: center;

}

.feed-post-e-comm{
  grid-column: 2/4;
  border-radius: var(--brad);

}
.ecomm-pic{
  width: 100%;
  border-radius: var(--brad);
}
.feed-post-e-comm-info{
  grid-row: 2/3;
  grid-column: 2/4;

}
.feed-post-button-border{
  padding: 0 20px;
}


.feed-post-interactions{
grid-row: 5/6;
    padding-top: 20px;

}