
/* .nav-container:hover, .nav-container.expanded {
    width: var(--nav-hw);
    overflow: hidden;
    opacity: 1;
} */

/* MAYBE MAKE THE PAGE GRID
NAV Home Home SideDrawer
*/

.nav-container {
    height: 100vh;
    width: var(--nav-w);
    position: fixed;
    z-index:1;
    top: 0;

    background-image: linear-gradient(
        to right,
         #611821 0%, 
         #771c26 25%, 
         #8e202b 50%,
        #a5242f 75%,
        #bd2932 100%
         );
    background-size: 250% 200%;
    animation: movingGradient 5s linear infinite alternate;
    color: var(--sec-t);
    border-radius: var(--rbrad);
    overflow-x: hidden ;
    /* -webkit-transform:translateZ(0) scale(1, 1);
    box-shadow: 1px 0 15px rgba(0, 0, 0, 0.07);
    opacity:1; */

    display: flex;
    flex-direction: column;
}

@keyframes movingGradient {
    from {background-position: 0 0;}
    to{ background-position: 100% 100%;}
}

.logo-link{
    width: 50%;
}
.logo{
    width: 100%;
}

.nav-buttons {
/* margin: 7px 0; */
padding-left: 0;
align-self: center;
display: flex;
flex-direction: column;
justify-content: center;
/* align-items: flex-end; */
width: 100%;
height: 100%;
}


.nav-list {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* border: 1px solid gold; */
    /* height: auto; */
}
.top-half{
height: auto;
justify-self: center;
align-self: center;
}
.bottom-half{
    height: auto;
    justify-self: center;
align-self: center;
}
.welcome{
    text-align: center;
}
.top{
    /* align-self: start; */
    height: auto;
}
.middle{
    /* align-self: center; */
    justify-content: center;
align-self: center;
    height: 70%;
    width: 50%;
}
.middle a, .bottom a{
    padding-top: var(--navbutpad);
    padding-bottom: var(--navbutpad);
    /* padding: var(--navbutpad); */
    margin-top: var(--navbutpad);
    margin-bottom:var(--navbutpad);
}
.middle .test, .bottom .test{
    /* padding: 10px 50px; */
    width: 100%;
}
.bottom {
    align-self: center;
height: 15%;
width: 50%;
}

.nav-list a {
    position: relative;
    width: auto;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* border-collapse:collapse; */
    /* border-spacing:0; */
    color:var(--sec-t);
    font-size: 2rem;
    text-decoration:none;
    -webkit-transform:translateZ(0) scale(1, 1);
    -webkit-transition:all .14s linear;
    transition:all .14s linear;
    font-family: 'Strait',
    sans-serif;    
    text-shadow: 1px 1px 1px #fff;
    /* border: 1px solid white; */
}



.test {
    /* border: 1px solid white; */
    border-radius: var(--brad);
    background-color: var(--tri-c);
    margin-top: var(--navbutpad);
    margin-bottom:var(--navbutpad);
}
.test1 {
    /* border: 1px solid white; */
    background-color: var(--pri-c);

}
.test2 {
    /* border: 1px solid white; */
    background-color: var(--sec-t);
    color:var(--tri-c);
}
.test2 a {
    color: var(--tri-c);
}

.test:hover, .test1:hover{
    background-color: var(--sec-c);
}
/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
    .nav-container {
    height:15vh;
    width: 100vw;
    position: fixed;
    z-index:100;
    top: 0;

    background-image: linear-gradient(
        to right,
         #611821 0%, 
         #771c26 25%, 
         #8e202b 50%,
        #a5242f 75%,
        #bd2932 100%
         );
    background-size: 250% 200%;
    animation: movingGradient 5s linear infinite alternate;
    color: var(--sec-t);
    border-radius: var(--bbrad);
    overflow-x: hidden ;

    display: flex;
    flex-direction: row;
}
.nav-buttons {
padding-left: 0;
align-self: center;
display: flex;
flex-direction: column;
justify-content: center;
width: 100%;
height: 50%;
}

.logo{
    width: 70%;
}
.nav-list {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}
.top-half{
height: auto;
justify-self: center;
align-self: center;
    width: 25%;

}
.bottom-half{
    height: auto;
    justify-self: center;
align-self: center;
    width: 100%;

}
} */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
     .nav-container {
    height:15vh;
    width: 100vw;
    position: fixed;
    z-index:100;
    top: 0;

    background-image: linear-gradient(
        to right,
         #611821 0%, 
         #771c26 25%, 
         #8e202b 50%,
        #a5242f 75%,
        #bd2932 100%
         );
    background-size: 250% 200%;
    animation: movingGradient 5s linear infinite alternate;
    color: var(--sec-t);
    border-radius: var(--bbrad);
    overflow-x: hidden ;


    display: flex;
    flex-direction: row;
}
.nav-buttons {
padding-left: 0;
align-self: center;
display: flex;
flex-direction: column;
justify-content: center;
width: 100%;
height: 50%;
}

.logo{
    width: 70%;
}
.nav-list {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.top-half{
height: auto;
justify-self: center;
align-self: center;
    width: 25%;

}
.bottom-half{
    height: auto;
    justify-self: center;
align-self: center;
    width: 100%;

}
}
/* Medium devices (landscape tablets, 768px and up) */
/* TRAIL OF DIFFERENCE IN TABLET LAPTOP */
@media only screen and (max-width: 991px) {
    .nav-container {
    height:15vh;
    width: 100vw;
    position: fixed;
    z-index:100;
    top: 0;

    background-image: linear-gradient(
        to right,
         #611821 0%, 
         #771c26 25%, 
         #8e202b 50%,
        #a5242f 75%,
        #bd2932 100%
         );
    background-size: 250% 200%;
    animation: movingGradient 5s linear infinite alternate;
    color: var(--sec-t);
    border-radius: var(--bbrad);
    overflow-x: hidden ;
    /* -webkit-transform:translateZ(0) scale(1, 1);
    box-shadow: 1px 0 15px rgba(0, 0, 0, 0.07);
    opacity:1; */

    display: flex;
    flex-direction: row;
}
.nav-buttons {
/* margin: 7px 0; */
padding-left: 0;
align-self: center;
display: flex;
flex-direction: column;
justify-content: center;
/* align-items: flex-end; */
width: 100%;
height: 50%;
}

.logo{
    width: 70%;
}
.nav-list {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* border: 1px solid gold; */
    /* height: auto; */
}
.top-half{
height: auto;
justify-self: center;
align-self: center;
    width: 25%;

}
.bottom-half{
    height: auto;
    justify-self: center;
align-self: center;
    width: 100%;

}
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
.nav-container {
    height: 100vh;
    width: var(--nav-w);
    position: fixed;
    z-index:1;
    top: 0;

    background-image: linear-gradient(
        to right,
         #611821 0%, 
         #771c26 25%, 
         #8e202b 50%,
        #a5242f 75%,
        #bd2932 100%
         );
    background-size: 250% 200%;
    animation: movingGradient 5s linear infinite alternate;
    color: var(--sec-t);
    border-radius: var(--rbrad);
    overflow-x: hidden ;
    /* -webkit-transform:translateZ(0) scale(1, 1);
    box-shadow: 1px 0 15px rgba(0, 0, 0, 0.07);
    opacity:1; */

    display: flex;
    flex-direction: column;
}
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
.nav-container {
    height: 100vh;
    width: var(--nav-w);
    position: fixed;
    z-index:1;
    top: 0;

    background-image: linear-gradient(
        to right,
         #611821 0%, 
         #771c26 25%, 
         #8e202b 50%,
        #a5242f 75%,
        #bd2932 100%
         );
    background-size: 250% 200%;
    animation: movingGradient 5s linear infinite alternate;
    color: var(--sec-t);
    border-radius: var(--rbrad);
    overflow-x: hidden ;
    /* -webkit-transform:translateZ(0) scale(1, 1);
    box-shadow: 1px 0 15px rgba(0, 0, 0, 0.07);
    opacity:1; */

    display: flex;
    flex-direction: column;
}
}